<template>
  <v-container id="regular-tables" fluid tag="section">

    <base-material-card color="success" icon="mdi-message-alert" title="Requests" class="px-5 py-3">

      <CCardBody>
        <v-spacer></v-spacer>
        <v-row>           

        <v-text-field v-model="search" label="Search" class="mx-4"/>
        <v-btn color="success" class="mr-0"  @click="DownloadPdfItem()">
            Download Selected CV
          </v-btn>
      </v-row>
        <v-data-table show-select v-model="SelectedCv" :headers="JobRequestheaders" :items="JobRequests" :items-per-page="5" sort-by="calories"
          class="elevation-1" :search="search">
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="OpenPdfItem(item)">
              mdi-file
            </v-icon>
            <!-- <v-icon
small
@click="deleteItem(item)"
>
mdi-delete
</v-icon> -->
          </template>
        </v-data-table>
      </CCardBody>
    </base-material-card>


    <base-material-card color="success" icon="mdi-clipboard-plus" title="Add New Career"
      class="px-5 py-3">

      <v-container class="py-0">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field label="Job Name" class="purple-input" v-model="Finalitem.JobName" />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field label="Salary" class="purple-input" :disabled="Finalitem.afterInterview"
              v-model="Finalitem.Salary" />
          </v-col>

          <v-col cols="12" md=2>
            <v-checkbox v-model="Finalitem.afterInterview" label="After the interview"></v-checkbox>
          </v-col>

          <v-col cols="12" md="6">
            <v-combobox v-model="Finalitem.CareerType" :items="CareerType" item-text="TypeName" item-value="id"
              return-object label="Job Type" outlined dense></v-combobox>

          </v-col>
          <v-col cols="12" sm="6">

            <v-combobox v-model="Finalitem.WorkTime" :items="WorkTime" label="Work Time" outlined dense></v-combobox>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field label="Experince" class="purple-input" outlined dense v-model="Finalitem.Experince" />
          </v-col>

          </v-col>
          <v-col cols="12" md="2" />
          <v-col cols="12" md="6">

            <v-combobox v-model="Finalitem.location" :items="location" label="Location" outlined dense></v-combobox>
          </v-col>

          <v-col cols="6" md="6">
            <v-row>
              <v-switch color="primary" v-model="Finalitem.sendByEmail">

                <template v-slot:label>
                  Send Via Email :
                </template>
              </v-switch>
              <div v-if="Finalitem.sendByEmail">


                <v-text-field style="margin-left: 20px;" label="Email" v-model="Finalitem.Email" class="purple-input" />

              </div>
            </v-row>
          </v-col>
          <v-col cols="6" md="6">


            <center>
              <v-checkbox dense v-model="Finalitem.IsActive" label="Active"></v-checkbox>


            </center>
          </v-col>

          <v-col cols="12" md="12">
            <v-textarea v-model="Finalitem.description">
              <template v-slot:label>
                <div>
                  Description
                </div>
              </template>
            </v-textarea>

          </v-col>
          <v-col cols="12" md="6">
            <div>
              <div v-for="(imgs, index) in form.Images" :key="`t-${imgs}`" style="display: inline-block;">

                <v-icon @click="ondelete(index)"> mdi-close</v-icon>
                <img class="img" :src="imgs" width="200" height="200">
              </div>


            </div>

          </v-col>

        </v-row>
        <template>
          <v-btn color="success" class="mr-0" @click="InsertItemToDB">
            Add New Career
          </v-btn>
        </template>



      </v-container>
    </base-material-card>

    <base-material-card icon="mdi-clipboard-text" title="Items View" class="px-5 py-3">
      <v-data-table :headers="headers" :items="Careers" sort-by="calories" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Career List</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="8" md="8">
                        <v-text-field v-model="editedItem.JobName" label="Dessert name" outlined dense></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <v-text-field v-model="editedItem.WorkTime" label="Work Time" outlined dense></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-combobox v-model="editedItem.CareerType" :items="CareerType" item-text="TypeName"
                          item-value="id" return-object label="Job Type" outlined dense></v-combobox>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-combobox v-model="editedItem.location" :items="location" item-text="location" item-value="id"
                          return-object label="Location" outlined dense></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="editedItem.Experince" label="Experince" outlined dense></v-text-field>
                      </v-col>

                      <v-col cols="6" md="6">
                        <v-row>
                          <v-switch color="primary" v-model="editedItem.sendByEmail">

                            <template v-slot:label>
                              Send Via Email :
                            </template>
                          </v-switch>
                          <div v-if="editedItem.sendByEmail">


                            <v-text-field style="margin-left: 20px;" label="Email" v-model="editedItem.Email"
                              class="purple-input" />

                          </div>
                        </v-row>
                      </v-col>
                      <v-col cols="6" md="6">


                        <center>
                          <v-checkbox dense v-model="editedItem.IsActive" label="Active"></v-checkbox>


                        </center>
                      </v-col>


                      <v-col cols="12" sm="12" md="12">
                        <v-textarea v-model="editedItem.description" label="Description" outlined dense>
                          <template v-slot:label>
                            <div>
                              Description
                            </div>
                          </template>
                        </v-textarea>

                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>


    <div class="py-3" />

  </v-container>



</base-material-card></template>

<script>

import { db, fb } from '@/db'
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      ItemDetails: [],
      collapseDuration: 0,
      WorkTime: [
        'Full-Time',
        'PartTime',
        'Alternative schedule',
        'Flexible schedule'],
      location: [
        'Toronto - Canada',
        'Amman - Jordan',
        'Cairo - Egypt',
        'Ramallah - Palestine',
        'Riyadh - Saudi Arabia ',
        'Dubai - United Emirates '
      ],
      selected: [],
      headers: [
        {
          text: 'Job Title',
          align: 'start',
          sortable: false,
          value: 'JobName',
        },
        { text: 'Experince', value: 'Experince' },
        { text: 'WorkTime', value: 'WorkTime' },
        { text: 'Salary', value: 'Salary' },
        { text: 'location', value: 'location' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      SelectedCv: [
      ],
      JobRequestheaders: [
        {
          text: 'Job Title',
          align: 'start',
          value: 'job_title',
        },
        { text: 'From', value: 'from_name' },
        { text: 'Email', value: 'from_email' },
        { text: 'Phone', value: 'from_Phone' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      form: {
        Images: [],
        snackbar: false,
        snakMsg: "Uploaded success"
      },
      Finalitem: {
        Salary: 0.0,
        IsActive: true,
        description: '',
        afterInterview: true,
        sendByEmail: false,
        Email: '',
      },
      ImageFire: [],
      dialog: false,
      dialogDelete: false,
      search: '',
      desserts: [],
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
      },

    }
  },
  computed: {

    CareerType() {
      return this.$store.state.CareerType
    },
    Careers() {
      return this.$store.state.Careers
    },
    JobRequests() {
      return this.$store.state.Requests
    },
    usertype() {
      return this.$store.state.userType
    },
    formTitle() {
      return 'Career'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      
      val || this.closeDelete()
    },
  },

  methods: {
    toggleDetails(item, index) {
      this.$set(this.MainItem[index], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0 })
    },
    InsertItemToDB() {
      
      var item = this.Finalitem;
      item.CareerType = this.Finalitem.CareerType.id
      
      var context = this;
      
      db.collection('Career')
        .add({ ...item })
        .then(docRef => {
          
          db.collection('Career')
            .doc(docRef.id)
            .update({ "ID": docRef.id })
            .then(_ => {
              this.Finalitem = {};
              alert("Career has been added successfully");
            })

        })
    },
    rowClick(item, row) {
      var found = false;
      for (var i = 0; i < this.selected.length; i++) {
        if (this.selected[i].ItemName == item.ItemName) {
          found = true;
          break;
        }
      }
      if (!found) {
        var newitem = Object.assign({}, item);
        this.Finalitem.Cost = this.Finalitem.Cost + parseFloat(newitem.ItemCost) + parseFloat(newitem.AdditionalCost);
        this.selected.push(newitem);
      } else {
        alert("Item Already Used");
      }

    },
    filterOnlyCapsText(value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
    },
    editItem(item) {
      
      this.editedIndex = this.Careers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    OpenPdfItem(item) {
      window.open(item.resume, '_blank');
    },
    DownloadPdfItem() {
    
    },

    deleteItem(item) {
      
      this.editedIndex = this.Careers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteMainItem(item, index) {
      var context = this;
      db.collection('Career').doc(item.ID)
        .delete().then(_ => {
          const storageRef = fb.storage().ref('CV/' + item.ID);
          storageRef.listAll().then((listResults) => {
            const promises = listResults.items.map((item) => {
              return item.delete();
            });
            Promise.all(promises);
          });




          alert("Career Was Deleted ...")
          context.MainItem.splice(index);
        })

    },
    deleteItemConfirm() {
      
      this.selected.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      
      this.dialogDelete = false
      this.deleteMainItem(this.editedItem, this.editedIndex);
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },

    save() {
      
      if (this.editedIndex > -1) {
        db.collection('Career').doc(this.editedItem.ID)
          .update(this.editedItem).then(_ => {
            alert("Career Was Updated ...")
          })

      } else {
        this.selected.push(this.editedItem)
      }
      this.close()


    },

    onPickFile() {
      this.$refs.fileinput.click();
    },
    ondelete(index) {
      this.form.Images.splice(index, 1);

    },
    onfilepicked(event) {
      const files = event.target.files
      let filename = files[0].name
      if (filename.lastIndexOf('.') <= 0) {
        return alert('please add a valid file !')
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.form.Images.push(reader.result)
      })
      reader.readAsDataURL(files[0])
      this.ImageFire.push(files[0])
    },
    getRowItem() {
      db
        .collection('Items')
        .doc('RowItem')
        .collection('Material')
        .get()
        .then(snapshots => {
          if (snapshots.docs.length === 0) { return [] }
          const exchange = snapshots.docs.map(doc => ({ ...doc.data() }))
          this.setdesserts(exchange)

        })
    },
    getMainItem() {
      db
        .collection('Items')
        .doc('MainItem')
        .collection('Material')
        .get()
        .then(snapshots => {
          if (snapshots.docs.length === 0) { return [] }
          const exchange = snapshots.docs.map(doc => ({ ...doc.data(), id: doc.id, components: doc.data().components }))

          this.setMainItem(exchange)

        })
    },
    getTypeofItem() {
      db
        .collection('Categories')
        .get()
        .then(snapshots => {

          if (snapshots.docs.length === 0) { return [] }
          const Types = snapshots.docs.map(doc => ({ ...doc.data(), id: doc.id, components: doc.data().components }))

          this.setTypeofItem(Types)

        })
    },
    getcategoryName: async function (CategoriesID) {
      return await db.collection('Categories')
        .doc(CategoriesID)
        .get()
        .then(async snapshot => {


          let x = await snapshot.data().C_Name
          document.getElementById(CategoriesID).innerHTML = x


          return document.SupplierName;

        })
    },
    set_component(components) {
      this.components = components
    },
    setdesserts(desserts) {

      this.desserts = desserts
    },
    setMainItem(MainItem) {
      this.MainItem = MainItem
    },
    setTypeofItem(Type) {
      this.Type = Type
    },
    setDetailsItem(ItemDetails) {

      this.ItemDetails = ItemDetails
    },
  },
  created() {
    this.$store.dispatch('bindAllDistribution', 'null')
    this.$store.dispatch('bindCareerType');
    this.$store.dispatch('bindCareers');
    this.$store.dispatch('bindRequests');
    this.getRowItem()
    this.getMainItem()
    this.getTypeofItem()
  }
}
</script>


<style lang="scss">
.tim-note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  width: 260px;
}
</style>
